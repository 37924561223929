import React, { useContext } from 'react'
import moment from 'moment'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Routes from '../../routes'
import Avatar from 'react-avatar'

import './nav.scss'
import OpenInNewIcon from 'components/Icons/OpenInNewIcon'
import Logo from 'components/common/Logo'
import AppContext from 'context/AppContext'

const TopNav = ({ isAuthenticated, lastRunTime }) => {
  const { version, packagingType } = useContext(AppContext)
  const userName = localStorage.getItem('username')

  return (
    <header className="sticky-top">
      <Navbar className="top-nav">
        <Container>
          <Navbar.Brand href={Routes.DASHBOARD.PATH}>
            <Logo height={40} alt={Routes.DASHBOARD.TITLE} />
          </Navbar.Brand>
          <Navbar.Toggle />
          {isAuthenticated && (
            <div className="display">
              <Navbar.Collapse className="gap-3">
                <Navbar.Text className="info-avatar">
                  <span>Welcome {userName}</span>
                  <span>
                    {lastRunTime && `AWS spend as of ${moment.utc(lastRunTime).local().format('MM/DD/yyyy hh:mm A')}`}
                  </span>
                </Navbar.Text>

                <NavDropdown title={<Avatar name={userName} size="40" round={true} />} className="nav-menu">
                  <section className="info-app">
                    <a href="https://docs.finopscenter.com/v1/docs/end-user-documentation" target="_blank">
                      Documentation <OpenInNewIcon className="nav-menu-icon" />
                    </a>
                    <p>Release v{version}</p>
                    <p>Version: {packagingType}</p>
                  </section>

                  <NavDropdown.Divider />
                  <Nav.Item key={Routes.LOGOUT.PATH} className="dropdown-item">
                    <Link to={Routes.LOGOUT.PATH}>Logout</Link>
                  </Nav.Item>
                </NavDropdown>
              </Navbar.Collapse>
            </div>
          )}
        </Container>
      </Navbar>
    </header>
  )
}

export default TopNav
