import React, { useState, useEffect } from 'react'
import './App.scss'
import AppContext from './context/AppContext'
import TopNav from './components/TopNavBar/TopNav'
import Footer from './components/Footer/Footer'
import { withRouter } from 'react-router-dom'
import MainContent from './components/MainContent'
import { isLoggedIn } from './utils'
import getSorElements from './common/use-cases/get-sor-elements'
import getSorMapping from 'common/use-cases/get-sor-mapping'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'

const currentYear = new Date().getFullYear()

const App = ({ repoFactory, location, accountMappingLimit, version, packagingType }) => {
  const [sorElements, setSorElements] = useState(null)
  const [sorElementMapping, setSorElementMapping] = useState(null)
  const [lastRunTime, setLastRunTime] = useState(null)
  const [availableYears, setAvailableYears] = useState([currentYear - 1])
  const [selectedYear, setSelectedYear] = useState(currentYear - 1)

  const fetchSorMapping = (year, isLoggedIn) => {
    if (isLoggedIn) {
      getSorMapping(year, {
        commonRepo: repoFactory.commonRepo(),
        observer: {
          success: (data) => {
            const { availableYears, currentYearMapping } = data
            setAvailableYears(availableYears)
            setSelectedYear(year)
            setSorElementMapping(currentYearMapping)
          },
          failure: (error) => {
            // TOOD: handle error
          }
        }
      })
    }
  }

  useEffect(() => {
    if (currentYear) {
      fetchSorMapping(currentYear, isLoggedIn())
    }
  }, [currentYear, isLoggedIn, localStorage.getItem('expirationTime')])

  useEffect(() => {
    if (selectedYear && selectedYear !== currentYear) {
      fetchSorMapping(selectedYear, isLoggedIn())
    }
  }, [currentYear, selectedYear, isLoggedIn, localStorage.getItem('expirationTime')])

  useEffect(() => {
    if (isLoggedIn()) {
      getSorElements(selectedYear, {
        commonRepo: repoFactory.commonRepo(),
        observer: {
          elementsReceived: (elements) => {
            setSorElements(elements)
          },
          errorGettingData: () => {
            setSorElements(null)
          },
          receiveLastRunTime: (time) => {
            setLastRunTime(time)
          }
        }
      })
    }
  }, [repoFactory, setSorElements, selectedYear, setLastRunTime, sorElementMapping])

  return (
    <AppContext.Provider
      value={{
        repoFactory,
        sorElements,
        selectedYear,
        setSelectedYear,
        availableYears,
        lastRunTime,
        accountMappingLimit,
        version,
        packagingType,
        sorElementMapping
      }}
    >
      <ToastContainer />
      <TopNav isAuthenticated={isLoggedIn()} lastRunTime={lastRunTime} />
      <div className="App">
        <MainContent location={location} repoFactory={repoFactory} isAuthenticated={isLoggedIn()} />
      </div>
      <Footer />
    </AppContext.Provider>
  )
}

export default withRouter(App)
