const uploadSor = ({ token, fileName, fileType, fileContent }, { sorRepo, observer }) => {
  sorRepo.uploadSOR(
    { token, fileName, fileType, fileContent },
    {
      success: (response) => {
        debugger
        if (response?.uploadSOR?.error) {
          observer.error()
          return
        }

        observer.success()
      },
      failure: (e) => {
        observer.error(e)
      }
    }
  )
}

export default uploadSor
